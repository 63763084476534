<template>
  <div id="backdrop"></div>
  <div class="navigation">
    <div class="container" id="navContainer">
      <router-link to="/">
        <img style="    max-height: 120px;
    width: fit-content;
    max-width: 120px;
    position: absolute;
    top: 10px;
    background: black;
    border-radius: 50%;" src="../assets/images/lmr-logo.svg">
      </router-link>
      <svg id="hamburger" @click="openMobileNav()" class="hamburger" xmlns="http://www.w3.org/2000/svg"
           clip-rule="evenodd" fill-rule="evenodd" height="512" stroke-linejoin="round" stroke-miterlimit="2"
           viewBox="0 0 24 24" width="512">
        <g id="Icon">
          <path d="m4 6.75h16c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-16c-.414 0-.75.336-.75.75s.336.75.75.75z"/>
          <path d="m4 12.75h16c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-16c-.414 0-.75.336-.75.75s.336.75.75.75z"/>
          <path d="m4 18.75h16c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-16c-.414 0-.75.336-.75.75s.336.75.75.75z"/>
        </g>
      </svg>
      <svg id="close" @click="openMobileNav()" class="close-button" xmlns="http://www.w3.org/2000/svg" width="571"
           height="571" viewBox="0 0 571 571" fill="none">
        <path
            d="M99.3228 492.668C93.8606 492.668 88.3985 490.59 84.2484 486.409C75.9172 478.078 75.9172 464.571 84.2484 456.239L456.239 84.2484C464.571 75.9172 478.078 75.9172 486.409 84.2484C494.74 92.5797 494.74 106.087 486.409 114.423L114.423 486.409C110.242 490.564 104.78 492.668 99.3228 492.668V492.668Z"
            fill="white"/>
        <path
            d="M471.34 492.668C465.878 492.668 460.421 490.59 456.265 486.409L84.2484 114.423C75.9172 106.087 75.9172 92.5797 84.2484 84.2484C92.5797 75.9172 106.087 75.9172 114.423 84.2484L486.409 456.239C494.74 464.571 494.74 478.078 486.409 486.409C482.228 490.564 476.771 492.668 471.34 492.668Z"
            fill="white"/>
      </svg>
      <ul id="navigationUl">
        <li>
          <router-link to="/" @click="openMobileNav()">Home</router-link>
        </li>
        <li>
          <router-link to="/parcours" @click="openMobileNav()">Parcours</router-link>
        </li>
        <li>
          <router-link to="/fotos" @click="openMobileNav()">Foto's</router-link>
        </li>
        <li>
          <router-link to="/sponsors" @click="openMobileNav()">Sponsoren</router-link>
        </li>
        <li>
          <a href="https://inschrijven.nl/form/2025070551516-nl" target="_blank">Inschrijven</a>
        </li>
        <li>
          <a href="https://uitslagen.nl" target="_blank">Uitslagen</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      dropdownToggle: false,
    }
  },
  watch: {
    $route(to, from) {

      const width = window.innerWidth || document.documentElement.clientWidth ||
          document.body.clientWidth;

      if (width < 800) {

        const navigationUl = document.getElementById('navigationUl')
        const navContainer = document.getElementById('navContainer')

        const backdrop = document.getElementById('backdrop')

        const hamburger = document.getElementById('hamburger')
        const close = document.getElementById('close')
        navigationUl.classList.remove('dropdown')
        navContainer.style.borderBottom = 'unset'
        this.dropdownToggle = !this.dropdownToggle
        this.dropdownToggle = false

        hamburger.style.display = 'block'
        backdrop.style.display = 'none'
        close.style.display = 'none'
      }
    }
  },
  methods: {
    openMobileNav() {

      const width = window.innerWidth || document.documentElement.clientWidth ||
          document.body.clientWidth;

      if (width < 800) {


        const navigationUl = document.getElementById('navigationUl')
        const navContainer = document.getElementById('navContainer')

        const hamburger = document.getElementById('hamburger')
        const close = document.getElementById('close')

        const backdrop = document.getElementById('backdrop')

        if (!this.dropdownToggle) {
          navigationUl.classList.add('dropdown')
          navContainer.style.borderBottom = 'solid 1px #dedede'
          this.dropdownToggle = !this.dropdownToggle
          hamburger.style.display = 'none'
          close.style.display = 'block'

          backdrop.style.display = 'block'
        } else {
          navigationUl.classList.remove('dropdown')
          navContainer.style.borderBottom = 'unset'
          this.dropdownToggle = !this.dropdownToggle

          hamburger.style.display = 'block'
          close.style.display = 'none'

          backdrop.style.display = 'none'
        }

      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="scss">
$gold: #FFE800;
$coal: #000000;
$green: #000000;

.router-link-exact-active {
  font-weight: 700 !important;
}

.dropdown {
  display: flex !important;
}

#backdrop {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  z-index: 25;
}

.navigation {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  background: $green;
  border-bottom: solid 2px $gold;
  max-width: 100vw;
  img {
    width: 200px;
    height: auto;
  }
  h4 {
    font-weight: 300;
    font-size: 22px;
  }


  .container {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-family: 'Avenir LT Std', sans-serif;
      text-decoration: none;
    }
  }

  .hamburger, .close-button {
    display: block;
    width: 32px;
    max-height: 30px;
    min-height: 30px;
    padding: 5px;
    min-width: 32px;
    height: fit-content;
    cursor: pointer;
    background-color: $gold;

    * {
      fill: white;
    }

    @media (min-width: 800px) {
      display: none;
    }
  }

  .close-button {
    display: none;
  }

  ul {
    display: flex;
    list-style: none;
    gap: 40px;
    margin: 10px;
    @media (max-width: 800px) {
      display: none;
      position: absolute;
      flex-direction: column;
      left: 30px;
      top: 120px;
      background: $green;
      &::after {
        content: "";
        background-color: $green;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: 620px;
        z-index: -1;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-bottom: $gold solid 5px;
      }
    }

    li {
      font-family: 'Rajdhani', sans-serif;

      a, p {
        font-weight: 500;
        font-size: 22px;
        color: white;
        text-decoration: none;
        transition: .3s;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
